import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import DialogMessageItem from './components/dialogMessageItem';

const useStyles = makeStyles({
  dialogTableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    color: '#999999',
    width: '100%',
    minHeight: '60px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'PingFang SC',
    fontSize: '14px',
    lineHeight: '20px',
  },
  headerItem: {
    justifyContent: 'center',
  },
});

const DialogMessageTable = ({ data, viewDetail }) => {
  const classes = useStyles();

  return (
    <div className={classes.dialogTableContainer}>
      <Grid container className={classes.header}>
        <Grid container item xs={2} className={classes.headerItem}>
          日期/时间
        </Grid>
        <Grid container item xs={2} className={classes.headerItem}>
          对话人
        </Grid>
        <Grid container item xs={2}>
          对话预览
        </Grid>
      </Grid>
      <div>
        {
          data.map((item) => item.content && (
            <DialogMessageItem
              key={item.id}
              data={item}
              viewDetail={viewDetail}
            />
          ))
        }
      </div>

    </div>
  );
};

export default DialogMessageTable;
