import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  imageItem: {
    '& > img': {
      maxWidth: '300px',
      maxHeight: '400px',
    },
  },
});

const ImageMessageItem = ({
  attachment,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.imageItem}>
      <img src={`/attachment/${attachment}`} alt="" />
    </div>
  );
};

export default ImageMessageItem;
